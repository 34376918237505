<template>
  <div class="page-index">
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{
        boxShadow:
          showBottom == true
            ? '5px 5px 13px #f2f2f2,-5px -5px 13px #ffffff'
            : 'none'
      }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/">
              <img src="../assets/imgs/logoText.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu">
              <i class="menu iconfont icon-chanpinjieshaoye-gengduo" />
            </a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>
              <!-- <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link> -->
              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <!-- <li @click="toSuggestion">
                <span>意见与反馈</span>
              </li> -->
              <!-- <li @click="toOldVersion">
                <span>前往旧版</span>
              </li> -->
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <img src="../assets/imgs/you.png" alt="" />
                    </span>
                  </router-link>
                  <img class="person" src="../assets/imgs/people.png" alt="" />
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <!-- <router-link to="/register">
                    <span class="register">注册</span>
                  </router-link> -->
                  <router-link to="/wait" target="_blank">
                    <el-button type="primary" class="login">登录</el-button>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>
        <!-- <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link> -->
        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 背景图 -->
    <div class="bannerImg" id="nav">
      <div class="center">
        <h1 class="slogan">企智评</h1>
        <h4 class="slogan_en">企业大数据AI智能评估平台</h4>
        <p class="desc">
          <span>丰富检索字段</span>
          精准锁定目标企业
        </p>
        <p class="desc">
          <span>多维评价指数</span>
          全面评估企业风险和价值
        </p>
        <p class="desc">
          <span>AI分析模型</span>
          提供一键智能大数据分析报告
        </p>
        <div id="user" v-if="isLogin" class="hidden-sm-and-up">
          <router-link to="/home">
            <span class="into">
              进入系统
              <img src="../assets/imgs/you.png" alt="" />
            </span>
          </router-link>
          <img class="person" src="../assets/imgs/people.png" alt="" />
          <a id="userName">{{ userName }}</a>
        </div>
        <div v-else class="button hidden-sm-and-up">
          <!-- <router-link to="/register">
                    <span class="register">注册</span>
                  </router-link> -->
          <router-link to="/wait" target="_blank">
            <el-button type="primary" class="login">点击登录</el-button>
          </router-link>
        </div>
      </div>
    </div>

    <!--适用场景-->
    <div class="tab-box">
      <h1 class="title">适用场景</h1>
      <div class="tab">
        <div class="tab-tabs hidden-xs-only">
          <el-row>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(1)"
                :class="{ active: isTab == 1 }"
              >
                <span>精准招商</span>
                <p>了解企业发展，吸引企业外资</p>
                <img src="../assets/imgs/accurate-investment-attraction.png" />
              </div>
            </el-col>

            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(2)"
                :class="{ active: isTab == 2 }"
              >
                <span>精准获客</span>
                <p>识别潜在客户，扩展客户资源</p>
                <img src="../assets/imgs/accurate-customer-acquisition.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(3)"
                :class="{ active: isTab == 3 }"
              >
                <span>商业情报</span>
                <p>掌握企业动态，优化商业决策</p>
                <img src="../assets/imgs/business-intelligence.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(4)"
                :class="{ active: isTab == 4 }"
              >
                <span>风险评估</span>
                <p>评估企业风险，评价企业表现</p>
                <img src="../assets/imgs/risk-assessment.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(5)"
                :class="{ active: isTab == 5 }"
              >
                <span>合规审查</span>
                <p>审查企业运作，规范企业经营</p>
                <img src="../assets/imgs/compliance-review.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(6)"
                :class="{ active: isTab == 6 }"
              >
                <span>行业研究</span>
                <p>分析行业现行发展，把握市场动向</p>
                <img src="../assets/imgs/industrial-research.png" />
              </div>
            </el-col>
          </el-row>
          <!-- <div
            class="tab-item"
            @click="changeTab(1)"
            :class="{ active: isTab == 1 }"
            style="padding: 15px; padding-right: 80px; padding-left: 70px"
          >
            <i
              class="iconfont icon-jingzhunzhaoshang"
              style="font-size: 35px; margin: 0px 8px 0px 8px"
            />
          </div>
          <div
            class="tab-item"
            @click="changeTab(2)"
            :class="{ active: isTab == 2 }"
            style="padding: 15px; padding-right: 80px; padding-left: 70px"
          >
            <i
              class="iconfont icon-jingzhunhuoke hidden-xs-only"
              style="font-size: 35px; margin: 0px 8px 0px 8px"
            />
            <span>精准获客</span>
            <p style="margin-top: 10px">识别潜在客户，扩展客户资源</p>
          </div>
          <div
            class="tab-item"
            @click="changeTab(3)"
            :class="{ active: isTab == 3 }"
            style="padding: 15px; padding-right: 80px; padding-left: 70px"
          >
            <i
              class="iconfont icon-shangyeqingbao hidden-xs-only"
              style="font-size: 32px; margin: 0px 9px 0px 9px"
            />
            <span>商业情报</span>
            <p style="margin-top: 10px">掌握企业动态，优化商业决策</p>
          </div>
          <div
            class="tab-item"
            @click="changeTab(4)"
            :class="{ active: isTab == 4 }"
            style="
              padding-top: 5px;
              padding-bottom: 15px;
              padding-right: 80px;
              padding-left: 70px;
            "
          >
            <i
              class="iconfont icon-fengxianpinggu hidden-xs-only"
              style="font-size: 50px; margin: 0px 0px 0px 3px"
            />
            <span>风险评估</span>
            <p class="hidden-xs-only" style="margin-top: 0px">
              评估企业风险，评价企业表现
            </p>
          </div> -->
        </div>
        <div class="tab-tabs hidden-sm-and-up">
          <el-row>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(1)"
                :class="{ active: isTab == 1 }"
              >
                <span>精准招商</span>
                <p>了解企业发展，吸引企业外资</p>
                <img src="../assets/imgs/accurate-investment-attraction.png" />
              </div>
            </el-col>

            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(2)"
                :class="{ active: isTab == 2 }"
              >
                <span>精准获客</span>
                <p>识别潜在客户，扩展客户资源</p>
                <img src="../assets/imgs/accurate-customer-acquisition.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(3)"
                :class="{ active: isTab == 3 }"
              >
                <span>商业情报</span>
                <p>掌握企业动态，优化商业决策</p>
                <img src="../assets/imgs/business-intelligence.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(4)"
                :class="{ active: isTab == 4 }"
              >
                <span>风险评估</span>
                <p>评估企业风险，评价企业表现</p>
                <img src="../assets/imgs/risk-assessment.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(5)"
                :class="{ active: isTab == 5 }"
              >
                <span>合规审查</span>
                <p>审查企业运作，规范企业经营</p>
                <img src="../assets/imgs/compliance-review.png" />
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
              <div
                class="tab-item"
                @click="changeTab(6)"
                :class="{ active: isTab == 6 }"
              >
                <span>行业研究</span>
                <p>分析行业现行发展，把握市场动向</p>
                <img src="../assets/imgs/industrial-research.png" />
              </div>
            </el-col>
          </el-row>
          <!-- <div
            class="tab-item"
            @click="changeTab(1)"
            :class="{ active: isTab == 1 }"
            style="padding: 22px"
          >
            <i
              class="iconfont icon-jingzhunzhaoshang"
              style="font-size: 22px"
            />
            <span>精准招商</span>
            <p style="margin-top: 10px">了解企业发展，吸引企业外资</p>
          </div>
          <div
            class="tab-item"
            @click="changeTab(2)"
            :class="{ active: isTab == 2 }"
            style="padding: 22px"
          >
            <i
              class="iconfont icon-jingzhunhuoke hidden-sm-and-up"
              style="font-size: 22px"
            />
            <span>精准获客</span>
            <p style="margin-top: 10px">识别潜在客户，扩展客户资源</p>
          </div>
          <div
            class="tab-item"
            @click="changeTab(3)"
            :class="{ active: isTab == 3 }"
            style="padding: 22px"
          >
            <i
              class="iconfont icon-shangyeqingbao hidden-sm-and-up"
              style="font-size: 18px"
            />
            <span>商业情报</span>
            <p style="margin-top: 10px">掌握企业动态，优化商业决策</p>
          </div>
          <div
            class="tab-item"
            @click="changeTab(4)"
            :class="{ active: isTab == 4 }"
            style="padding: 15px"
          >
            <i
              class="iconfont icon-fengxianpinggu hidden-sm-and-up"
              style="font-size: 30px"
            />
            <span>风险评估</span>
            <p class="hidden-sm-and-up" style="margin-top: 5px">
              评估企业风险，评价企业表现
            </p>
          </div> -->
        </div>
        <!-- <div class="tab-content">
          <img
            v-if="isTab === 1"
            src="../assets/imgs/accurate-investment-attraction.png"
          />
          <img
            v-if="isTab === 2"
            src="../assets/imgs/accurate-customer-acquisition.png"
          />
          <img
            v-if="isTab === 3"
            src="../assets/imgs/business-intelligence.png"
          />
          <img v-if="isTab === 4" src="../assets/imgs/risk-assessment.png" />
        </div> -->
      </div>
    </div>

    <!-- icon区域 -->
    <div class="icon-box">
      <h1 class="title">主要功能</h1>
      <div class="icon-banner">
        <el-row class="bgc" :gutter="20">
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider1')">
              <div class="fun-card">
                <div class="bg-circle">
                  <img src="../assets/imgs/target.png" />
                </div>
                <h4 class="function-name">精准查询</h4>
                <p class="function-desc">丰富的检索字段</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider2')">
              <div class="fun-card">
                <div class="bg-circle">
                  <img src="../assets/imgs/histogram.png" />
                </div>
                <h4 class="function-name">多维评价</h4>
                <p class="function-desc">多维度智能评估企业风险和价值</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider3')">
              <div class="fun-card">
                <div class="bg-circle">
                  <img src="../assets/imgs/analysis.png" />
                </div>
                <h4 class="function-name">智能分析</h4>
                <p class="function-desc">利用NLP技术智能分析商业信息</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider4')">
              <div class="fun-card">
                <div class="bg-circle">
                  <img src="../assets/imgs/layers.png" />
                </div>
                <h4 class="function-name">动态监测</h4>
                <p class="function-desc">实时动态监测关注企业列表</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 主体内容区域 -->
    <div class="nav">
      <div class="y-main" v-if="isMain">
        <div class="icon-banner">
          <el-row id="spider1" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-target.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">精准查询</h4>
                <p class="content-desc">丰富的检索字段</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      构建包括【企业基本信息】、【地理位置】、【企业规模】等在内的共12个查询类别，104个查询字段，快速锁定目标企业
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持风险指数、信用质量、经营能力等企业智能评价指数的查询
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持核心财务指标区间范围的查询</span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持产品与技术的查询</span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持涵盖运营、财务、人事等维度的商业信号的查询
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持自定义查询条件模板</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider2" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">多维评价</h4>
                <p class="content-desc">多维度智能评价企业风险和价值</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用新闻数据、专利数据、招投标及招聘数据评估企业的影响指数、创新指数、购买指数、销售指数及招聘指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业关键财务指标、交易特征、经营记录等数据评估企业的内部风险指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业所处行业、上下游行业情况以及企业间存在财务或经营活动往来等关联关系评估企业的外部风险指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业的内外环境、经营水平、股东背景等数据评估企业的信用质量
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业财报、交易、经营活动各项记录数据，评估企业的生存能力
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业资产利用效率、盈利指标、财务指标、行业位置等数据评估企业的经营能力
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-histogram.jpg" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider3" class="bgc container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-analysis.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">智能分析</h4>
                <p class="content-desc">基于NLP技术分析企业商业信息</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用NLP技术对海量新闻数据提炼关键词，分析新闻报道主旨
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      通过新闻报道途径整理企业运营、财务、人事等维度的数据，汇总企业商业信号
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      构建算法评估模型，估计企业的核心财务指标数值
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider4" class="bgc container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">动态监测</h4>
                <p class="content-desc">实时监测关注企业列表</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持保存搜索条件，并将搜索条件列表化展示，方便查看与管理
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持将查询出来的企业通过创建列表进行统一管理
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持对查询结果创建动态列表，追踪搜索条件的结果更新
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      关注列表支持批量创建，也支持批量添加到已有关注列表
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-layers.jpg" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="y-main" v-else>
        <div class="icon-banner">
          <el-row id="spider1" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-target.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">精准查询</h4>
                <p class="content-desc">丰富的检索字段</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      构建包括【企业基本信息】、【地理位置】、【企业规模】等在内的共12个查询类别，104个查询字段，快速锁定目标企业
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持风险指数、信用质量、经营能力等企业智能评价指数的查询
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持核心财务指标区间范围的查询</span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持产品与技术的查询</span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持涵盖运营、财务、人事等维度的商业信号的查询
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">支持自定义查询条件模板</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider2" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-histogram.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">多维评价</h4>
                <p class="content-desc">多维度智能评价企业风险和价值</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用新闻数据、专利数据、招投标及招聘数据评估企业的影响指数、创新指数、购买指数、销售指数及招聘指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业关键财务指标、交易特征、经营记录等数据评估企业的内部风险指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业所处行业、上下游行业情况以及企业间存在财务或经营活动往来等关联关系评估企业的外部风险指数
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业的内外环境、经营水平、股东背景等数据评估企业的信用质量
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业财报、交易、经营活动各项记录数据，评估企业的生存能力
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用企业资产利用效率、盈利指标、财务指标、行业位置等数据评估企业的经营能力
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider3" class="bgc container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-analysis.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">智能分析</h4>
                <p class="content-desc">基于NLP技术分析企业商业信息</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      利用NLP技术对海量新闻数据提炼关键词，分析新闻报道主旨
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      通过新闻报道途径整理企业运营、财务、人事等维度的数据，汇总企业商业信号
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      构建算法评估模型，估计企业的核心财务指标数值
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider4" class="bgc container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-layers.jpg" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">动态监测</h4>
                <p class="content-desc">实时监测关注企业列表</p>
                <ul class="content-item">
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持保存搜索条件，并将搜索条件列表化展示，方便查看与管理
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持将查询出来的企业通过创建列表进行统一管理
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      支持对查询结果创建动态列表，追踪搜索条件的结果更新
                    </span>
                  </li>
                  <li>
                    <span class="dot"></span>
                    <span class="text">
                      关注列表支持批量创建，也支持批量添加到已有关注列表
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <!-- <div class="footer-middle">
        <div class="bottom-menu">
          <span>关于我们</span>
          <span>服务协议</span>
          <span>隐私政策</span>
        </div>
      </div> -->
      <div class="footer-contact">
        <div class="content">
          <p class="content-title">联系我们</p>
          <p class="content-info">
            若您有问题，请添加企业客服微信，或者您也可以通过下面的邮箱地址向我们咨询：
            <br />
            marketing@monetware.com
          </p>
        </div>
        <div class="QR-code">
          <img src="../assets/imgs/QR-code.png" />
          <p>企业客服微信</p>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            2023 上海萌泰数据科技股份有限公司
          </a>
          Inc. All rights reserved.&ensp;&ensp;
          <a
            href="http://beian.miit.gov.cn"
            target="_blank"
            :style="
              ($route.name == 'login' || $route.name == 'register') &&
              'color: #000000;'
            "
          >
            沪ICP备09099644号-19
          </a>
        </p>
      </div>
    </div>
    <el-dialog title="跳转提示" v-model="dialogVisible" width="30%">
      <span>
        2022年1月27日及此后的所有旧版数据都将不会和新版进行同步。是否进行跳转？
      </span>
      <!-- <span slot="footer" class="dialog-footer"> -->
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmToOldVersion">
            确 定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// import VueCookies from "vue-cookies";
// import { httpPost, httpPostIsAnonymous } from "../api/httpService";
export default {
  data() {
    return {
      isActive: 1, // 切换导航
      isTab: 1, //切换tab
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      isMain: true,
      screenWidth: "",
      isLogo: true,
      dialogVisible: false
    };
  },

  // created() {
  //   // /*判断用户是否登陆*/
  //   if (VueCookies.isKey("tokenWeb")) {
  //     let userInfo = VueCookies.get("userInfo");
  //     this.userName = userInfo.name;
  //     httpPost("/auth/role", null).then((res) => {
  //       if (res && res.code === 0) {
  //         userInfo.nlpPermission = res.data.role;
  //         userInfo.ifLoginNlp = true;
  //         VueCookies.set("userInfo", JSON.stringify(userInfo));
  //         VueCookies.set(
  //           "userInfo",
  //           JSON.stringify(userInfo),
  //           2 * 60 * 60,
  //           "/",
  //           "ringdata.com",
  //           false
  //         );
  //       }
  //     });
  //     this.isLogin = true;
  //   } else {
  //     this.isLogin = false;
  //   }
  // },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth <= 768) {
      this.isMain = false;
      this.isLogo = false;
    } else {
      this.isMain = true;
      this.isLogo = true;
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth <= 768) {
          this.isMain = false;
          this.isLogo = false;
        } else {
          this.isMain = true;
          this.isLogo = true;
        }
      })();
    };
    this.addViewTimes();
  },

  methods: {
    toSuggestion() {
      window.open(
        "https://www.ringdata.com/feedback?productCategory=2&productType=5"
      );
    },
    toOldVersion() {
      // console.log("???");
      this.dialogVisible = true;
      // window.open("https://wx.ringdata.com")
    },
    confirmToOldVersion() {
      this.dialogVisible = false;
      window.open("https://wx.ringdata.com/old/");
    },

    // 切换导航
    changeActive(current) {
      if (current != 1) {
        this.destroyed();
      }
      this.isActive = current;
    },

    //切换tab
    changeTab(current) {
      this.isTab = current;
    },

    // 锚点跳转
    goSpider(id) {
      if (this.isMain == true) {
        this.$el.querySelector(id).scrollIntoView(true);
      } else {
        this.$el.querySelector(id + "1").scrollIntoView(true);
      }
    },

    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    // 销毁滚轮事件
    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },

    // 增加访问次数
    addViewTimes() {
      // let key = "VIEW_NLP";
      // httpPostIsAnonymous("/show/update/view/" + key, null).then((res) => {
      //   if (res && res.code === 0) {
      //     // console.log("")
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-index {
  .mr {
    margin-right: 0 !important;
  }
  .icon-banner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .people {
    width: 15px;
    height: 17px;
    vertical-align: middle;
    padding-bottom: 2px;
  }

  #user {
    line-height: 0;
    .person {
      display: inline-block;
      width: 18px;
      vertical-align: middle;
      margin-bottom: 4px;
      margin-right: 3px;
    }
  }
  .bug {
    cursor: pointer;
    width: 35px;
    height: 60px;
    float: right;
    padding-right: 20px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    a {
      cursor: pointer;
      width: 35px;
      height: 60px;
      display: inline-block;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .menu {
    font-size: 28px;
    // color: #666;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 40px;
    position: relative;
    top: 10px;
  }
  .menuBox {
    z-index: 1000;
    position: fixed;
    top: 60px;
    left: 0;
    transform: translateX(400%);
    width: 20%;
    text-align: left;
    line-height: 50px;
    background-color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    li {
      cursor: pointer;
      z-index: 1000;
      a {
        margin-left: 10px;
        &:hover {
          color: #188b99;
        }
        &.active {
          color: #188b99;
        }
      }
    }
    .menu-setting {
      border-top: 1px solid #fff;
      padding-top: 15px;
      .register {
        width: 70px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-right: 40px;
      }
    }
  }
  .into {
    margin-left: 30px;
    margin-right: 10px;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 13px;
    img {
      display: inline-block;
      width: 20px;
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }
  a,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: #444;
  }
  button,
  button:focus {
    outline: none;
  }
  ul,
  ol {
    list-style: none;
    padding: 0;
  }

  // 头部
  .page-header {
    position: fixed;
    left: 0;
    top: 0;
    height: 60px;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    z-index: 10;
    .navbar-header {
      width: 20%;
      height: 60px;
      line-height: 60px;
      float: left;
      padding: 0px 10px 0px;
      img {
        display: inline-block;
        width: 98px;
        height: 35px;
        margin-left: 65px;
        vertical-align: middle;
      }
    }
    .header-right {
      float: right;
      text-align: right;
      ul {
        width: 100%;
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        margin-right: 50px;
        li {
          margin-right: 20px;
          float: left;
          cursor: pointer;
          a {
            font-weight: bold;
            &.active {
              color: #188b99;
            }
          }
          .register {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            margin-left: 20px;
          }
          i {
            color: #606266;
          }
          #userImg {
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-right: 5px;
            margin-left: 10px;
          }
          #userName {
            margin-left: 2px;
            display: inline-block;
            padding: 0;
            line-height: 60px;
            font-size: 14px;
            color: #777;
          }
          #userName:hover {
            color: #777;
          }
          .login {
            padding: 8px 13px;
            // margin-left: 30px;
            margin-bottom: 3px;
            background-color: rgba(24, 139, 153, 1);
            border-color: rgba(24, 139, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 12px;
            height: 25px;
            font-family: Roboto;
          }
        }
      }
    }
  }

  // 背景图
  .bannerImg {
    width: 100%;
    color: #188b99;
    text-align: center;
    background: url("../assets/imgs/bg.jpg");
    background-size: cover;
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 790px;
    .center {
      padding: 200px 0;
      .slogan {
        text-align: left;
        font-size: 60px;
        color: rgba(24, 139, 153, 1);
        font-family: SourceHanSansSC-regular;
        margin-top: 20px;
        margin-left: 65px;
        padding: 30px 15px 10px;
      }
      .slogan_en {
        text-align: left;
        color: #000000;
        font-family: SourceHanSansSC-regular;
        font-size: 40px;
        margin-left: 65px;
        margin-top: 10px;
        padding: 0 15px 30px;
      }
      .desc {
        font-size: 20px;
        text-align: left;
        font-weight: 500;
        color: #000000;
        margin-left: 65px;
        margin-bottom: 15px;
        padding: 0 15px;
        span {
          color: #188b99;
          margin-right: 5px;
          font-family: SourceHanSansSC-regular;
        }
      }
    }
  }

  // tab
  .tab-box {
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 38px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      margin: 100px 0px 50px 0px;
    }
    .tab {
      display: flex;
      // align-items: center;
      justify-content: center;
      .tab-tabs {
        width: 75%;
        // .tab-item {
        //   cursor: pointer;
        //   display: block;
        //   margin: 20px;
        //   border-radius: 6px;
        //   i {
        //     vertical-align: middle;
        //     font-weight: 500;
        //   }
        //   span {
        //     vertical-align: middle;
        //     color: rgba(16, 16, 16, 1);
        //     font-size: 22px;
        //     font-family: SourceHanSansSC-bold;
        //     font-weight: bold;
        //     margin-left: 20px;
        //   }
        //   p {
        //     color: rgba(108, 108, 108, 1);
        //     font-size: 12px;
        //     margin-top: 5px;
        //     margin-left: 5px;
        //     font-family: SourceHanSansSC-regular;
        //     visibility: hidden;
        //   }
        // }
        .el-col {
          display: flex;
          justify-content: center;
        }
        .tab-item {
          padding: 20px;
          width: 60%;
          text-align: center;
          cursor: pointer;
          display: block;
          margin: 20px;
          border-radius: 6px;
          span {
            color: rgba(16, 16, 16, 1);
            font-size: 22px;
            font-family: SourceHanSansSC-bold;
            font-weight: bold;
          }
          p {
            color: rgba(108, 108, 108, 1);
            font-size: 12px;
            margin-top: 20px;
            font-family: SourceHanSansSC-regular;
          }
          img {
            margin-top: 20px;
            width: 100%;
          }
        }
        .tab-item:hover {
          box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
          border-radius: 6px;
          // i {
          //   color: #188b99;
          // }
          // span {
          //   color: #188b99;
          // }
          // p {
          //   visibility: visible;
          // }
        }
        .tab-item.active {
          box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
          border-radius: 6px;
          // i {
          //   color: #188b99;
          // }
          // span {
          //   color: #188b99;
          // }
          // p {
          //   visibility: visible;
          // }
        }
      }
      // .tab-content {
      //   margin-left: 200px;
      //   img {
      //     width: 450px;
      //     height: 450px;
      //   }
      // }
    }
  }

  // ICON
  .icon-box {
    background-color: #ffffff;
    padding: 50px 40px;
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 38px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      margin: 50px 0px 50px 0px;
    }
    .el-col {
      display: flex;
      justify-content: center;
      .function-grid {
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        padding: 40px;
        width: 80%;
        min-height: 250px;
        background: #ffffff;
        box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
        border-radius: 6px;
        font-family: Roboto;
        .fun-card {
          .bg-circle {
            width: 120px;
            height: 120px;
            text-align: center;
            line-height: 120px;
            background-color: #188b99;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%;
            img {
              margin-top: 30px;
              width: 60px;
              height: 60px;
            }
          }
        }
        .function-name {
          font-size: 18px;
          text-align: center;
          padding: 30px 0px 30px;
          color: rgba(16, 16, 16, 1);
          font-family: SourceHanSansSC-regular;
        }
        .function-desc {
          color: rgba(56, 56, 56, 1);
          font-size: 12px;
          text-align: center;
          font-family: SourceHanSansSC-regular;
        }
      }
      .function-grid:hover {
        background: #ffffff;
        box-shadow: 18px 18px 36px #ebebeb, -18px -18px 36px #ffffff;
        border-radius: 6px;
      }
    }
  }

  // 主体内容区域
  .y-main {
    margin-top: 30px;
    .bgc {
      background-color: #fff;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    .bgf {
      background-color: #fff;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    .main-img {
      padding: 20px 40px;
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
      }
    }
    display: flex;
    align-items: center;
    .main-text {
      padding: 10px 40px;
      .content-title {
        font-size: 28px;
        margin: 20px 0px 15px 0px;
        color: rgba(16, 16, 16, 1);
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .content-desc {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0px 20px 0px;
        color: rgba(16, 16, 16, 1);
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .content-item {
        list-style: disc;
        color: rgba(56, 56, 56, 1);
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        li {
          margin: 10px 0px;
          .dot {
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #188b99;
            margin-right: 8px;
          }
          .text {
            // display: inline-block;
            // vertical-align: middle;
          }
        }
      }
    }
  }

  // 底部
  .footer {
    padding: 30px 10px;
    text-align: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    // .footer-middle {
    //   color: #a0a0a0;
    //   padding: 10px 0px;
    //   font-size: 12px;
    //   span {
    //     margin: 0px 5px;
    //     cursor: pointer;
    //   }
    // }
    .footer-contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75%;
      height: 400px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .content-title {
          font-size: 22px;
          font-weight: 600;
        }
        .content-info {
          margin-top: 50px;
          text-align: left;
          line-height: 25px;
          font-size: 14px;
        }
      }
      .QR-code {
        img {
          width: 150px;
          height: auto;
        }
        p {
          margin-top: 10px;
          color: #6c6c6c;
        }
      }
    }
    .footer-bottom {
      font-size: 12px;
      p {
        color: #a0a0a0;
      }
      a {
        color: #337ab7;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .page-index {
    .mr {
      margin-right: 0 !important;
    }
    .icon-banner {
      max-width: 1200px;
      margin: 0 auto;
    }
    .people {
      width: 15px;
      height: 17px;
      vertical-align: middle;
      padding-bottom: 2px;
    }

    #user {
      line-height: 0;
      .person {
        display: inline-block;
        width: 18px;
        vertical-align: middle;
        margin-bottom: 4px;
        margin-right: 3px;
      }
    }
    .bug {
      cursor: pointer;
      width: 35px;
      height: 60px;
      float: right;
      padding-right: 10px;
      display: inline-block;
      -webkit-tap-highlight-color: transparent;
      a {
        cursor: pointer;
        width: 35px;
        height: 60px;
        display: inline-block;
        -webkit-tap-highlight-color: transparent;
      }
    }
    .menu {
      font-size: 28px;
      // color: #666;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      line-height: 40px;
      position: relative;
      top: 10px;
    }
    .menuBox {
      z-index: 1000;
      position: fixed;
      top: 60px;
      left: 0;
      transform: translateX(320%);
      width: 22%;
      text-align: left;
      line-height: 50px;
      background-color: #fff;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      li {
        cursor: pointer;
        z-index: 1000;
        a {
          margin-left: 10px;
          &:hover {
            color: #188b99;
          }
          &.active {
            color: #188b99;
          }
        }
      }
      .menu-setting {
        border-top: 1px solid #fff;
        padding-top: 15px;
        .register {
          width: 70px;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          margin-right: 40px;
        }
      }
    }
    .into {
      margin-left: 30px;
      margin-right: 10px;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 13px;
      img {
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-bottom: 4px;
      }
    }
    a,
    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
      color: #444;
    }
    button,
    button:focus {
      outline: none;
    }
    ul,
    ol {
      list-style: none;
      padding: 0;
    }

    // 头部
    .page-header {
      position: fixed;
      left: 0;
      top: 0;
      height: 60px;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      z-index: 10;
      .navbar-header {
        width: 20%;
        height: 60px;
        line-height: 60px;
        float: left;
        padding: 0px 10px 0px;
        img {
          display: inline-block;
          width: 70px;
          height: 25px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .header-right {
        float: right;
        text-align: right;
        ul {
          width: 100%;
          height: 60px;
          line-height: 60px;
          overflow: hidden;
          li {
            margin-right: 20px;
            float: left;
            cursor: pointer;
            a {
              font-weight: bold;
              &.active {
                color: #188b99;
              }
            }
            .register {
              font-size: 15px;
              font-weight: 500;
              color: #000;
              margin-left: 20px;
            }
            i {
              color: #606266;
            }
            #userImg {
              display: inline-block;
              width: 13px;
              height: 13px;
              margin-right: 5px;
              margin-left: 10px;
            }
            #userName {
              margin-left: 2px;
              display: inline-block;
              padding: 0;
              line-height: 60px;
              font-size: 14px;
              color: #777;
            }
            #userName:hover {
              color: #777;
            }
            .login {
              padding: 8px 13px;
              margin-left: 15px;
              text-align: left;
              margin-bottom: 3px;
              background-color: rgba(24, 139, 153, 1);
              border-color: rgba(24, 139, 153, 1);
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              height: 25px;
              font-family: Roboto;
            }
          }
        }
      }
    }

    // 背景图
    .bannerImg {
      width: 100%;
      color: #188b99;
      text-align: center;
      background: url("../assets/imgs/phone-bg.jpg");
      background-size: cover;
      // background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 790px;
      .center {
        padding: 100px 0;
        .slogan {
          text-align: left;
          font-size: 30px;
          color: rgba(24, 139, 153, 1);
          font-family: SourceHanSansSC-regular;
          margin-top: 20px;
          margin-left: 10px;
          padding: 30px 15px 10px;
        }
        .slogan_en {
          text-align: left;
          color: #000000;
          font-family: SourceHanSansSC-regular;
          font-size: 25px;
          margin-left: 10px;
          margin-top: 10px;
          padding: 0 15px 30px;
        }
        .desc {
          font-size: 14px;
          text-align: left;
          font-weight: 500;
          color: #000000;
          margin-left: 10px;
          margin-bottom: 15px;
          padding: 0 15px;
          span {
            color: #188b99;
            margin-right: 5px;
            font-family: SourceHanSansSC-regular;
          }
        }
        // .register {
        //   font-size: 15px;
        //   font-weight: 500;
        //   color: #000;
        //   margin-left: 20px;
        // }
        // i {
        //   color: #606266;
        // }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          margin-left: 2px;
          display: inline-block;
          padding: 0;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
        .button {
          text-align: left;
          margin-left: 25px;
          margin-top: 25px;
          .login {
            background-color: rgba(24, 139, 153, 1);
            border-color: rgba(24, 139, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-family: Roboto;
          }
        }
      }
    }

    // tab
    .tab-box {
      .title {
        color: rgba(16, 16, 16, 1);
        font-size: 25px;
        text-align: center;
        font-family: SourceHanSansSC-regular;
        margin: 50px 0px 50px 0px;
      }
      .tab {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .tab-tabs {
          display: flex;
          flex-wrap: wrap;
          width: 90%;
          .tab-item {
            padding: 15px;
            cursor: pointer;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            margin: 15px;
            span {
              vertical-align: middle;
              color: rgba(16, 16, 16, 1);
              font-size: 14px;
              font-family: SourceHanSansSC-bold;
              font-weight: bold;
              margin-left: 10px;
            }
            p {
              color: rgba(108, 108, 108, 1);
              font-size: 12px;
              margin-top: 10px;
              margin-left: 5px;
              font-family: SourceHanSansSC-regular;
            }
          }
          .tab-item:hover {
            box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
            border-radius: 6px;
            // i {
            //   color: #188b99;
            // }
            // span {
            //   color: #188b99;
            // }
            // p {
            //   visibility: visible;
            // }
          }
          .tab-item.active {
            box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
            border-radius: 6px;
            // i {
            //   color: #188b99;
            // }
            // span {
            //   color: #188b99;
            // }
            // p {
            //   visibility: visible;
            // }
          }
        }
        .tab-content {
          margin: 0 auto;
          img {
            width: 300px;
            height: 300px;
          }
        }
      }
    }

    // ICON
    .icon-box {
      background-color: #ffffff;
      padding: 0px 20px;
      .title {
        color: rgba(16, 16, 16, 1);
        font-size: 25px;
        text-align: center;
        font-family: SourceHanSansSC-regular;
        margin: 50px 0px 50px 0px;
      }
      .el-col {
        display: flex;
        justify-content: center;
        .function-grid {
          display: flex;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          text-align: center;
          padding: 40px;
          width: 90%;
          min-height: 220px;
          background: #ffffff;
          box-shadow: 5px 5px 14px #ebebeb, -5px -5px 14px #ffffff;
          border-radius: 6px;
          font-family: Roboto;
          margin-bottom: 20px;
          .fun-card {
            .bg-circle {
              width: 100px;
              height: 100px;
              text-align: center;
              line-height: 120px;
              background-color: #188b99;
              margin: 0 auto;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              border-radius: 50%;
              img {
                margin-top: 25px;
                width: 50px;
                height: 50px;
              }
            }
          }
          .function-name {
            font-size: 16px;
            text-align: center;
            padding: 30px 0px 30px;
            color: rgba(16, 16, 16, 1);
            font-family: SourceHanSansSC-regular;
          }
          .function-desc {
            color: rgba(56, 56, 56, 1);
            font-size: 12px;
            text-align: center;
            font-family: SourceHanSansSC-regular;
          }
        }
        .function-grid:hover {
          background: #ffffff;
          box-shadow: 18px 18px 36px #ebebeb, -18px -18px 36px #ffffff;
          border-radius: 6px;
        }
      }
    }

    // 主体内容区域
    .y-main {
      margin-top: 30px;
      .bgc {
        background-color: #fff;
        margin-top: 30px;
      }
      .bgf {
        background-color: #fff;
        margin-top: 30px;
      }
      .main-img {
        padding: 20px 20px;
        img {
          display: block;
          width: 100%;
          margin: 0 auto;
        }
      }
      .main-text {
        padding: 10px 20px;
        .content-title {
          font-size: 22px;
          margin: 20px 0px 15px 0px;
          color: rgba(16, 16, 16, 1);
          text-align: left;
          font-family: SourceHanSansSC-regular;
        }
        .content-desc {
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0px 20px 0px;
          color: rgba(16, 16, 16, 1);
          text-align: left;
          font-family: SourceHanSansSC-regular;
        }
        .content-item {
          list-style: disc;
          color: rgba(56, 56, 56, 1);
          line-height: 20px;
          font-size: 14px;
          text-align: left;
          font-family: SourceHanSansSC-regular;
          li {
            .dot {
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #188b99;
              margin-right: 8px;
            }
            margin: 10px 0px;
          }
        }
      }
    }

    // 底部
    .footer {
      padding: 30px 10px;
      text-align: center;
      background: #fff;
      .footer-contact {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 400px;
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .content-title {
            font-size: 22px;
            font-weight: 600;
          }
          .content-info {
            margin-top: 50px;
            text-align: left;
            line-height: 25px;
            font-size: 14px;
          }
        }
        .QR-code {
          img {
            width: 150px;
            height: auto;
          }
          p {
            margin-top: 10px;
            color: #6c6c6c;
          }
        }
      }
      .footer-bottom {
        font-size: 12px;
        p {
          color: #a0a0a0;
        }
        a {
          color: #337ab7;
        }
      }
    }
  }
}
</style>
